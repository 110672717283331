import React from 'react'

function Footer() {
  return (
    <div className="footer">
        <p>Copyright 2022 <a href="https://christianwebdeveloper.com">Christian Web Developer</a></p>
    </div>
  )
}

export default Footer
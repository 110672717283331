import React from 'react'

function Home() {
  return (
    <div>
      <h1>Welcome to Javascript Programmer!</h1>
      <p>This site is built with React, Javascript and Tailwind. I have too many domains to develop and 
        not enough time, what with a full time job besides. Stay tuned for updates coming soon!
      </p>
    </div>
  )
}

export default Home
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <div className="container flex mx-auto px-4 py-2">
      <Header />
      <Footer />
</div>
  );
}

export default App;

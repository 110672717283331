import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Home from "../pages/Home";

function Header() {
  return (
    <Router>
      <header className="flex space-x-40">
          <h1 className="text-xl font-bold text-orange-100">Welcome to Javascript Programmer!</h1>
        <nav className="flex flex-row justify-center align-center">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav>
        <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/" element={<Home />} />
        </Routes>
      </header>
    </Router>
  );
}

export default Header;
